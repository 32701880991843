<template>
  <v-card class="card-shadow mb-6">
    <div class="card-header-padding card-border-bottom">
      <p class="font-weight-600 text-h3 text-typo mb-0">Please choose a User</p>
    </div>
    <div class="card-border-bottom card-padding">
      <v-text-field
        hide-details
        class="input-style font-size-input text-light-input placeholder-light"
        dense
        flat
        filled
        solo
        height="43"
        placeholder="User 1"
      >
        <template slot="prepend-inner">
        </template>
      </v-text-field>
    </div>
    <v-card-text class="card-padding py-2">

    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "rule-select",
  data() {
    return {
      members: [
        {
          avatar: require("@/assets/img/team-1.jpg"),
          title: "John Michael",
          status: "Online",
          color: "text-success",
        },
        {
          avatar: require("@/assets/img/team-2.jpg"),
          title: "Alex Smith",
          status: "In a Meeting",
          color: "text-warning",
        },
        {
          avatar: require("@/assets/img/team-3.jpg"),
          title: "Samantha Ivy",
          status: "Offline",
          color: "text-danger",
        },
        {
          avatar: require("@/assets/img/team-4.jpg"),
          title: "Ali Connors",
          status: "Online",
          color: "text-success",
        },
      ],
    };
  },
};
</script>
