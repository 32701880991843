<template>
  <div>
    <header-top-dashboard primary>
      
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n13">
      <v-row>
        <v-col cols="12" md="12" class="py-0">
          <card-get-started></card-get-started>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";
import CardGetStarted from "../Components/Cards/CardGetStarted.vue";
import MembersCardSearch from "./Widgets/MembersCardSearch.vue";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ProgressTrack from "./Widgets/ProgressTrack.vue";
import VectorMapCard from "./Widgets/VectorMapCard.vue";
import ToDoList from "./Widgets/ToDoList.vue";
const today = new Date();
const y = today.getFullYear();
const m = today.getMonth();

export default {
  name: "widgets",
  components: {
    HeaderTopDashboard,
    CardGetStarted,
    MembersCardSearch,
    FullCalendar,
    ProgressTrack,
    VectorMapCard,
    ToDoList,
  },
  data() {
    let monthText = m + 1 > 10 ? m + 1 : `0${m + 1}`;
    let yearAndMonth = `${y}-${monthText}`;
    return {
      token: localStorage.getItem('jwt'),
      host:  this.$siteConfig.resellerApiHost,
      paths: {
        ping: `${this.$siteConfig.resellerApiBasePath}/reseller/ping`,
      },
      switche: true,
      miniCards: [
        {
          subtitle: "Total Traffic",
          title: "350,897",
          gradient: "bg-gradient-danger",
          icon: "ni-active-40",
        },
        {
          subtitle: "New Users",
          title: "2,356",
          gradient: "bg-gradient-warning",
          icon: "ni-chart-pie-35",
        },
        {
          subtitle: "Sales",
          title: "924",
          gradient: "bg-gradient-success",
          icon: "ni-money-coins",
        },
        {
          subtitle: "Performance",
          title: "49,65%",
          gradient: "bg-gradient-success",
          icon: "ni-chart-bar-32",
        },
      ],
      messages: [
        {
          img: require("@/assets/img/team-1.jpg"),
          name: "Tim",
          time: "2 hrs ago",
          title: "New order for Vuetify Argon",
        },
        {
          img: require("@/assets/img/team-2.jpg"),
          name: "Mike",
          time: "1 day ago",
          title: "Your theme has been updated",
        },
      ],
      timeline: [
        {
          title: "New message",
          color: "#b0eed3",
          iconColor: "#1aae6f",
          icon: "ni-bell-55",
          description: "Let's meet at Starbucks at 11:30. Wdyt?",
        },
        {
          title: "Product Issue",
          color: "#fdd1da",
          iconColor: "#f80031",
          icon: "ni-html5",
          description: "A new issue has been reported for Argon.",
        },
        {
          title: "New Likes",
          color: "#aaedf9",
          iconColor: "#03acca",
          icon: "ni-like-2",
          description: "Your posts have been liked a lot.",
        },
      ],
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        contentHeight: "auto",
        headerToolbar: false,
        events: [
          {
            title: "Call with Dave",
            start: `${yearAndMonth}-18`,
            end: `${yearAndMonth}-18`,
            className: "bg-red",
            allDay: true,
          },
          {
            title: "Lunch meeting",
            start: `${yearAndMonth}-21`,
            end: `${yearAndMonth}-22`,
            className: "bg-orange",
            allDay: true,
          },
          {
            title: "All day conference",
            start: `${yearAndMonth}-29`,
            end: `${yearAndMonth}-29`,
            className: "bg-green",
            allDay: true,
          },
          {
            title: "Meeting with Mary",
            start: `${yearAndMonth}-01`,
            end: `${yearAndMonth}-01`,
            className: "bg-blue",
            allDay: true,
          },
          {
            title: "Winter Hackaton",
            start: `${yearAndMonth}-03`,
            end: `${yearAndMonth}-03`,
            className: "bg-red",
            allDay: true,
          },
          {
            title: "Digital event",
            start: `${yearAndMonth}-07`,
            end: `${yearAndMonth}-09`,
            className: "bg-orange",
            allDay: true,
          },
          {
            title: "Marketing event",
            start: `${yearAndMonth}-10`,
            end: `${yearAndMonth}-10`,
            className: "bg-purple",
            allDay: true,
          },
          {
            title: "Dinner with Family",
            start: `${yearAndMonth}-19`,
            end: `${yearAndMonth}-19`,
            className: "bg-red",
            allDay: true,
          },
          {
            title: "Black Friday",
            start: `${yearAndMonth}-23`,
            end: `${yearAndMonth}-23`,
            className: "bg-blue",
            allDay: true,
          },
          {
            title: "Cyber Week",
            start: `${yearAndMonth}-02`,
            end: `${yearAndMonth}-02`,
            className: "bg-yellow",
            allDay: true,
          },
        ],
      },
    };
  },
  methods: {
    checkAuth: function() {
        fetch(`${this.$data.host}${this.$data.paths.ping}`, {
          mode: 'cors', // no-cors, *cors, same-origin
          headers: {
            'Authorization': `Bearer ${this.$data.token}`
          }
        })
          .then(response => {
            console.log(response);
            if (response.ok) {
              console.log('OK');
              return response.json()
            } else {
              console.log('NOT OK');
              return response.json();
            }
          })
          .then(json => {
            console.log('DATA:', json);

            if (json && json.data) {

            } else if (json && json.error) {
              alert(json.error);
            } else {
              throw new Error('Authentication error');
            }

          })
          .catch((e) => {
            localStorage.removeItem('jwt');
            localStorage.removeItem('user'); //TODO: fix
            alert('Authentication expired');
            this.$router.push('/login')
            console.log('Fetch error', e)
        });
    }
  },
  beforeMount(){
     this.checkAuth()
  }
};
</script>
